<template>
  <el-pagination
    class="content-page"
    background
    layout="prev, pager, next"
    :total="total"
    @current-change="goTo"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    total: Number
  },
  data () {
    return {}
  },
  methods: {
    goTo (val) {
      this.$emit('goTo', val)
    }
  },
  mounted: function () {

  }
}
</script>
<style lang="less">
  .content-page {
    .flex;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 3px !important;
        &.active {
          border: none !important;
        }
      }
    }
  }
</style>
