<template>
  <div class="app-item app-content">
    <h4>交易记录</h4>
    <el-table style="width: 100%" :data="tableData">
      <el-table-column prop="created_at" label="交易时间" align="center"></el-table-column>
      <el-table-column prop="money" label="交易价格" align="center"></el-table-column>
      <el-table-column label="说明" align="center">
        <template slot-scope="props">
          <p>{{ props.row.desc }}</p>
          <p>订单号:{{ props.row.order_no }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="status_show" label="交易状态" align="center"></el-table-column>
    </el-table>
    <Page :total="total" @goTo="goTo"></Page>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
export default {
  components: { Page },
  data () {
    return {
      total: 0,
      tableData: []
    }
  },
  methods: {
    getOrders () {
      this.$store.dispatch('getOrders').then(res => {
        // console.log(res)
        this.tableData = res.data
      })
    },
    goTo (page) {}
  },
  mounted () {
    this.getOrders()
  }
}
</script>
<style lang="less" scoped>
</style>
